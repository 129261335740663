import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Calendar from './Calendar';
import PlantList from './PlantList';
import styles from "./index.module.scss";

const drawerWidth = 240;
const closeDrawerWidth = 73;

const Garden = () => {
  const [gardenCategoryList, setGardenCategoryList] = useState([]);
  const [page, setPage] = useState('home');
  const categoryIcons = new Map();

  const plants = useSelector((state) => state.gardenPlants.data);
  const menuItems = useSelector((state) => state.gardenPlants.categoryNames);
  const menuOpen = useSelector((state) => state.myApp.menuOpen);
  const pageIds = useSelector((state) => state.gardenPlants.categoryIds);
  const plantsData = useSelector((state) => state.gardenPlants.categoryData);

  menuItems.forEach(item => {
    const p = plants.filter(plant => plant.location === item);
    categoryIcons.set(item, p[0].img_url);
  });

  useEffect(() => {
    setGardenCategoryList(menuItems);
  }, [menuItems, pageIds]);

  function onClickHandler(pageId) {
    setPage(pageId)
  }

  const drawer = (
    <div>
      <List>
        {
          gardenCategoryList?.map((item, index) =>
            <ListItem key={`garden-cat-${index}`} disablePadding>
              <ListItemButton onClick={() => {
                onClickHandler(item.replaceAll(" ", ""))
              }}>
                <ListItemIcon>
                  <img src={categoryIcons.get(item)} style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "20%"
                  }} alt={item}/>
                </ListItemIcon>
                <ListItemText sx={{
                  textTransform: "capitalize",
                  whiteSpace: 'nowrap',
                  fontSize: '0.8rem',
                }} primary={item} />
              </ListItemButton>
            </ListItem>
          )
        }
      </List>
    </div>
  );

  return (
    <>
      <Box component="nav" sx={{
        width: menuOpen ? drawerWidth : closeDrawerWidth ,
        flexShrink: { sm: 0 },
        transition: menuOpen ?
          'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms' :
          'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
      }}>
        <Drawer
          open={menuOpen}
          component="nav"
          variant="persistent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: menuOpen ? drawerWidth : closeDrawerWidth,
              boxSizing: 'border-box',
              paddingTop: '3rem',
              transform: menuOpen ? 'none': 'translateX(0) !important',
              visibility: 'visible !important',
              transition: menuOpen ?
                'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms' :
                'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important'
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component={"main"} sx={{
        padding: '5rem 2rem 2rem',
        width: '100%',
      }}>
        { pageIds.includes(page) ?
          <>
            <h1 className={styles.pageHeader}>
                {plantsData[page][0].location.toUpperCase()}
            </h1>
            <Grid container spacing={2} sx={{height: "100%"}}>
              <PlantList plantsData={plantsData[page]} />
            </Grid>
          </> : <Calendar />
        }
      </Box>
    </>
  );
};

export default Garden;

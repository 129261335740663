const DataService = () => {

  async function getGardenDataJson() {
    return fetch("/data/garden.json").then((res) => {
      if (res.ok) {
        return res.json()
      }
    }).catch(e => {
      console.log("Error getting garden data with error: ", e);
    });
  }

  function getDataJson(url) {
    const mapping = fetch("/data/mapping.json").then((res) => res.json());
    const data = fetch(url).then((res) => res.json());
    return Promise.all([mapping, data]).then((resp) => {
      let formatted = resp[1].map((obj) => {
        obj.img_url = obj.img_url.replace("\\", "");
        obj.light = stringToList(obj.light);
        obj.pest_resistant = stringToList(obj.pest_resistant);
        obj.bloom_time = stringToList(obj.bloom_time);
        obj.rhody_season = stringToList(obj.rhody_season);
        return obj;
      });
      return [resp[0], formatted];
    });
  }

  function stringToList(str) {
    if (typeof str === "string") {
      if (str.includes(",")) {
        return str.split(",").map((str) => parseInt(str));
      } else {
        return [str];
      }
    }
    if (typeof str === "number") {
      str = str.toFixed(0);
      return [str];
    }
    return [];
  }

  function getListItems(key, arr, mapping) {
    const html = arr.map((obj, i) => {
      if (i === arr.length - 1) {
        return mapping[key][obj];
      } else {
        return mapping[key][obj] + ", ";
      }
    });
    return <dd class="text-capitalize">{html}</dd>;
  }

  function getHeightAndSpread(data) {
    if (data.height && data.spread) {
      return (
        <>
          {data.spread}" x {data.height}"
        </>
      );
    }
    return <dd class="text-capitalize">No data</dd>;
  }

  function getTrueFalseLabel(data) {
    if (data) return "Yes";
    return "No";
  }

  return {
    getGardenDataJson: getGardenDataJson,
    getDataJson: getDataJson,
    getListItems: getListItems,
    getHeightAndSpread: getHeightAndSpread,
    getTrueFalseLabel: getTrueFalseLabel,
  };
};

export default DataService;

import { createSlice } from "@reduxjs/toolkit";

const initialDataState = {
  data: [],
  categoryNames: [],
  categoryIds: [],
  categoryData: {}
};

export const gardenPlantsSlice = createSlice({
  name: "gardenPlants",
  initialState: initialDataState,
  reducers: {
    initData: (state, action) => {
      // if (!state.data.length) {

        const plantData = action.payload
        const categories = [...new Set(state.data.map(item => item.location))].sort();
        const plantsList = {};

        state.data = plantData;
        state.categoryNames = categories;

        categories.forEach(item => {
          const p = plantData.filter(plant => plant.location === item);
          const area = item.replaceAll(" ", "");
          plantsList[area] = p;
        });
        state.categoryIds = Object.keys(plantsList);
        state.categoryData = plantsList;
      }
    // }
  }
});

export const {
  initData,
} = gardenPlantsSlice.actions;
export default gardenPlantsSlice.reducer;
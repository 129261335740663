import React from "react";
import styles from './index.module.scss';

const seasons = ['spring', 'summer', 'fall', 'winter'];
const tasks = ['prune', 'fertilize', 'mulch'];

const calendarData = {
  spring: {
    label: "Spring",
    prune: [
      { 'name': 'Snowberry', 'notes': 'Remove dead wood in early spring.' },
      { 'name': 'Liriope Muscari', 'notes': 'Mow or shear the foliage back to the ground before new growth.' },
      { 'name': 'Snowdrop Anemone', 'notes': 'Remove old foliage in early spring.' },
      { 'name': 'Lavender', 'notes': 'Prune 1/3 of the plant when it is young. Prune woody stems to 1-2 sets of leaves if it is leggy.' },
      { 'name': 'Ascot Rainbow Spurge', 'notes': 'Flower stems only.' },
      { 'name': 'Lenten Rose', 'notes': 'Remove faded flower stems.' },
      { 'name': 'Catmint', 'notes': 'Reshape and remove dead stuff.' },
      { 'name': 'Orange Rocket Barberry', 'notes': 'No pruning necessary except to maintain desired shape. Prune no more than 1/3 of its canopy, and all cuts should be on a slight angle facing away from the bud.' },
      { 'name': 'Black-Eyed Susan', 'notes': 'Remove old foliage before new leaves emerge. Divide clumps every 2 to 3 years in early spring.' },
      { 'name': 'Moonshine Yarrow', 'notes': '1-2 inches above ground.' },
      { 'name': 'Sweet Box', 'notes': 'To shape only.' },
      { 'name': 'Golden Variegated Hakone Grass', 'notes': 'Trim foliage to the ground in late winter to early spring before the new shoots emerge.' },
      { 'name': 'Boxleaf Euonymus', 'notes': 'To shape only.' },
      { 'name': 'Lady\'s Mantle', 'notes': 'Shear the old leaves from the crowns in early spring before new growth starts.' },
      { 'name': 'Alyssum \'Golden Spring\'', 'notes': 'Shear the faded flowers and foliage back by half with pruning shears when done blooming.' },
      { 'name': 'Blue & Gold Spiderwort', 'notes': 'Shear back each plant to 2 inches tall after flowering.' },
      { 'name': 'Allium', 'notes': 'Remove old stems.' },
      { 'name': 'Mum', 'notes': 'Cut down to 2-3 leaves per branch or 1"-2" height until 7/4.' },
      { 'name': 'Coral Bells', 'notes': 'Trim back tattered foliage in early spring.' },
    ],
    fertilize: [
      { 'name': 'Japanese Maple', 'notes': 'Shrub and tree fertilizer before May.' },
      { 'name': 'Little Henry Sweetspire', 'notes': 'Shrub and tree.' },
      { 'name': 'Astilbe', 'notes': 'Balanced.' },
      { 'name': 'Daylily', 'notes': 'Compose only.' },
      { 'name': 'Mr. Goldstrike Aucuba', 'notes': 'Shrub and tree.' },
      { 'name': 'Sawtoothed Japanese Aucuba', 'notes': 'Shrub and tree.' },
      { 'name': 'Camellia', 'notes': 'Acid loving.' },
      { 'name': 'Skip Schipka Laurel', 'notes': 'Shrub and tree.' },
      { 'name': 'Snowberry', 'notes': 'Balanced.' },
      { 'name': 'Hosta', 'notes': 'Balanced.' },
      { 'name': 'Pieris japonica \'Flaming Silver\'', 'notes': 'Acid loving.' },
      { 'name': 'Mock orange', 'notes': 'Balanced or rose.' },
      { 'name': 'Woodland peony', 'notes': 'Balanced or rose.' },
      { 'name': 'Lithodora \'Grace Ward\'', 'notes': 'No need unless leaves turn pale and yellow. Use acid loving fertilizer.' },
      { 'name': 'Liriope Muscari', 'notes': 'Balanced.' },
      { 'name': 'Lenten Rose', 'notes': 'Balanced.' },
      { 'name': 'Reeves Skimmia', 'notes': 'Balanced.' },
      { 'name': 'Peony', 'notes': 'Bulbs and tubers.' },
      { 'name': 'Hydrangea', 'notes': 'Acid loving.' },
      { 'name': 'Orange Rocket Barberry', 'notes': 'Shrub and tree.' },
      { 'name': 'Black-Eyed Susan', 'notes': 'Rose or flowers.' },
      { 'name': 'Sweet Box', 'notes': 'Shrub and tree.' },
      { 'name': 'Daphne', 'notes': 'Limited and balanced or shrub fertilizer only.' },
      { 'name': 'Creeping Phlox and Phlox', 'notes': 'Balanced.' },
      { 'name': 'Shasta Daisy', 'notes': 'Balanced, Rose or flowers.' },
      { 'name': 'Coneflower', 'notes': 'Balanced.' },
      { 'name': 'Goatsbeard', 'notes': 'Balanced.' },
      { 'name': 'Solomon\'s Seal', 'notes': 'Organic matter or compost.' },
      { 'name': 'Rhody', 'notes': 'Acid loving.' },
      { 'name': 'Iris', 'notes': 'Bulbs and tubers.' },
      { 'name': 'Blue & Gold Spiderwort', 'notes': 'Very little need. Can apply balanced fertilizer if needed.' },
      { 'name': 'Allium', 'notes': 'Bulbs and tubers.' },
      { 'name': 'Star Jasmine', 'notes': 'Balanced.' },
      { 'name': 'Clematis Integrifolia \'Durandii\'', 'notes': 'Rose or flowers.' },
      { 'name': 'Mum', 'notes': 'Balanced.' },
    ],
    mulch: [
      { 'name': 'Shasta Daisy', 'notes': 'Leaf mulch.' },
    ]
  },
  summer: {
    label: "Summer",
    prune: [
      { 'name': 'Mock orange', 'notes': 'After flowering.' },
      { 'name': 'Lungwort', 'notes': 'After flowering.' },
      { 'name': 'Columbine', 'notes': 'Cut the foliage to the ground around midsummer.' },
      { 'name': 'Lavender', 'notes': 'After flowering, prune 1/3 of the plant or slightly trim if the plant is leggy.' },
      { 'name': 'Catmint', 'notes': 'Early summer, by half length.' },
      { 'name': 'Black-Eyed Susan', 'notes': 'To extend the blooming season, cut some black-eyed Susan stems back by a third in early summer.' },
      { 'name': 'Iris', 'notes': 'Remove flower stems in late summer.' },
      { 'name': 'Blue & Gold Spiderwort', 'notes': 'Shear back each plant to 2 inches tall after flowering.' },
      { 'name': 'Mum', 'notes': 'Cut down to 2-3 leaves per branch or 1"-2" height until July 4th.' },
    ],
    fertilize: [
      { 'name': 'Snowberry', 'notes': 'Balanced.' },
      { 'name': 'Hydrangea', 'notes': 'Acid loving.' },
      { 'name': 'Star Jasmine', 'notes': 'Balanced.' },
    ],
    mulch: []
  },
  fall: {
    label: "Fall",
    prune: [
      { 'name': 'Hydrangea', 'notes': 'Remove spent flowers to shape. Avoid removing new leave buds.' },
      { 'name': 'Little Henry Sweetspire', 'notes': 'To shape.' },
      { 'name': 'Peony', 'notes': 'Cut down to ground level after turning brown.' },
      { 'name': 'Catmint', 'notes': 'To shape.' },
      { 'name': 'Black-Eyed Susan', 'notes': 'Leave a few inches above ground. Cut the plant all the way down to the ground if damaged.' },
      { 'name': 'Moonshine Yarrow', 'notes': '1 to 2 inches above ground.' },
      { 'name': 'Daphne', 'notes': 'To shape.' },
      { 'name': 'Golden Variegated Hakone Grass', 'notes': 'Cut down to ground level after turning brown.' },
      { 'name': 'Phlox', 'notes': '1 to 2 inches above ground.' },
      { 'name': 'Goatsbeard', 'notes': 'Cut down to ground level after turning brown.' },
      { 'name': 'Solomon\'s Seal', 'notes': 'Cut down to ground level after turning brown.' },
      { 'name': 'Iris', 'notes': 'Round fan shape.' },
      { 'name': 'Mum', 'notes': 'Dead head only.' },
      { 'name': 'Shasta Daisy', 'notes': 'After the foliage dies back in late fall, prune two to three inches above the soil.'},
      { 'name': 'Lavender', 'notes': 'About an inch from top for leggy plant. 1/3 of the plant if not leggy.' },
    ],
    fertilize: [
      { 'name': 'Hydrangea', 'notes': 'Balanced.' },
      { 'name': 'Creeping Phlox and Phlox', 'notes': 'Balanced.' },
      { 'name': 'Iris', 'notes': 'Bulb.' },
      { 'name': 'Allium', 'notes': 'Bulb.' },
    ],
    mulch: [
      { 'name': 'Trillium', 'notes': 'Leaf mulch.' },
      { 'name': 'Leopard Plant', 'notes': 'Leaf mulch.' },
      { 'name': 'Peony', 'notes': 'Away from crown.' },
      { 'name': 'Barrenwort', 'notes': 'Leaf mulch.' },
      { 'name': 'Shasta Daisy', 'notes': 'Leaf mulch.' },
      { 'name': 'Golden Variegated Hakone Grass', 'notes': 'Leaf mulch.' },
    ]
  },
  winter: {
    label: "Winter",
    prune: [
      { 'name': 'Clematis Integrifolia \'Durandii\'', 'notes': 'Late winter, down to 4"-6".' },
      { 'name': 'Coneflower', 'notes': 'Late winter, to the ground.' },
      { 'name': 'Mum', 'notes': 'Leave dead foliage or cut down to the ground.' },
    ],
    fertilize: [],
    mulch: []
  }
}

const Calendar = () => {

  function getTaskTable(season) {

    return (<>
      { tasks.map((task, j) => {
          return (
            <div key={`${season}-task-${j}`}>
              <table>
                <thead>
                <tr>
                  <th className={styles.columnDone}>Done?</th>
                  <th className={styles.columnPlant}>{task}</th>
                  <th>Notes</th>
                </tr>
                </thead>
                <tbody>
                { calendarData[season][task] &&
                  calendarData[season][task]?.map((row, i) =>
                    <tr key={`fall-prune-${i}`}>
                      <td><input type={'checkbox'}/></td>
                      <td>{row.name}</td>
                      <td>{row.notes}</td>
                    </tr>
                  )
                }
                </tbody>
              </table>
            </div>
          )
      })}
    </>)

  }


  return (<>
    <div className={styles.anchorLinks}>
      [ <a href="#spring">Spring</a> | <a href="#summer">Summer</a> | <a href="#fall">Fall</a> | <a href="#winter">Winter</a> ]
    </div>
    {
      seasons.map((season, i) => {
        return (
          <div key={`season-${i}`} id={`${season}`}>
            <h2 className={styles.pageHeader}>
              {season.toUpperCase()}
            </h2>
            { getTaskTable(season) }
            <hr></hr>
            <div className={styles.anchorLinks}>
              [ <a href="#spring">Spring</a> | <a href="#summer">Summer</a> | <a href="#fall">Fall</a> | <a href="#winter">Winter</a> ]
            </div>
          </div>
        )
      })
    }
  </>);
}

export default Calendar;
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { initData } from '../redux/features/gardenPlants';
import DataService from '../services/dataService';
import { useDispatch } from 'react-redux';

import Header from '../components/Header';
import Garden from '../components/Garden';

const theme = createTheme({
  palette: {
    primary: {
      main: "#e785ab",
      light: "#ffb6dd",
      dark: "#b3567c",
    },
    secondary: {
      main: "#abe785",
      light: "#deffb6",
      dark: "#7ab556",
    },
  },
});

function NoMatch() {
  return (
    <main style={{
      flexGrow: 1,
      padding: '4rem 1rem 1rem'
    }}>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </main>
  );
}

function App() {
  const { getGardenDataJson } = DataService();
  const dispatch = useDispatch();

  useEffect(() => {
    getGardenDataJson().then(d => {
      dispatch(initData(d));
    });
    // eslint-disable-next-line
  }, []);

  return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <div style={{display: "flex", height: '100vh'}}>
            <Header />
            <Routes>
              <Route index element={<Garden />} />
              {/*<Route path="/garden" element={<Garden />} />*/}
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </div>
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;

import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';
import DataService from '../../../services/dataService';

const {
  getHeightAndSpread,
  getTrueFalseLabel
} = DataService();

const PlantList = (props) => {
  const {plantsData} = props;
  const theme = useTheme();
  const dtStyles = {
    fontWeight: 500
  };
  const ddStyles = {
    marginBottom: '0.4rem',
    textTransform: 'capitalize'
  };
  const noData = "no data";

  return (<>
    {console.log(plantsData)}
    { plantsData && plantsData.map((plant, i) =>
        <Grid
          item
          key={`plant-${i}`}
          xs={12}
          md={6}
          xl={3}
        >
          <Card>
            <CardMedia
              image={plant.img_url}
              title="Contemplative Reptile"
              sx={{
                height: 200
              }}
            />
            <CardContent>
              <Typography gutterBottom component="h2">
                {plant.name}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Scientific Name
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {plant.sci_name}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Light Requirement
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {plant.light}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Bloom Time
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {plant.bloom_time ? plant.bloom_time : noData}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Spread and Height
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {getHeightAndSpread({
                  height: plant.height,
                  spread: plant.spread
                })}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Evergreen
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {getTrueFalseLabel(plant.evergreen)}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Native
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {getTrueFalseLabel(plant.native)}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Pest Resistant
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {plant.pest_resistant ? plant.pest_resistant: noData}
              </Typography>

              <Typography variant="body2" color={theme.palette.secondary.dark} component="dt" sx={dtStyles}>
                Plant Care
              </Typography>
              <Typography variant="body2" color="textSecondary" component="dd" sx={ddStyles}>
                {plant.notes ? plant.notes : noData}
              </Typography>

            </CardContent>
          </Card>
        </Grid>
      )
    }
  </>)
}

export default PlantList;
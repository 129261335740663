import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuState } from '../../redux/features/myApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styles from "./index.module.scss";

const Header = () => {
  const menuOpen = useSelector((state) => state.myApp.menuOpen);
  const dispatch = useDispatch();

  function toggleMenuOpen() {
    dispatch(setMenuState(!menuOpen));
  }

  return (
    <header>
      <AppBar position="fixed"
              sx={{
                zIndex: 1201,
                transition: menuOpen ?
                  'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms':
                  'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
                  ,
                width: menuOpen ? 'calc(100% - 240px)': '100%',
                marginLeft: menuOpen ? '240px' : 0
              }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleMenuOpen}
            edge="start"
            sx={{
              display: menuOpen ? 'none' : ''
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={toggleMenuOpen}
            sx={{
              display: menuOpen ? '' : 'none'
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <a href={"/"} className={styles.headerLink}>
            <Typography variant="h6" color="inherit" noWrap>
              Emma's Garden
            </Typography>
          </a>
        </Toolbar>
      </AppBar>
    </header>
  )
};

export default Header;
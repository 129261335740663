import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuOpen: true
};

export const myAppSlice = createSlice({
  name: "myApp",
  initialState: initialState,
  reducers: {
    setMenuState: (state, action) => {
      state.menuOpen = action.payload;
    }
  }
});

export const { setMenuState } = myAppSlice.actions;
export default myAppSlice.reducer;